






















































































import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Ticket from '@improve/common-utils/src/model/Ticket';
import Topic from '@improve/common-utils/src/model/Topic';
import Team from '@improve/common-utils/src/model/Team';
import User from '@improve/common-utils/src/model/User';
import ReactionsBottomCard
  from '@improve/common-components/src/components/widgets/ReactionsBottomCard.vue';
import BaseUserAvatar from '@improve/common-components/src/components/widgets/BaseUserAvatar.vue';
import EmojiViewer from '@improve/common-components/src/components/ticket/EmojiViewer.vue';
import BaseMenu from '@improve/common-components/src/components/widgets/BaseMenu.vue';
import BaseTicket from '@improve/common-components/src/helpers/BaseTicket.vue';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';

@Component({
  name: 'BaseTicketCard',
  components: {
    BaseMenu,
    BaseUserAvatar,
    ReactionsBottomCard,
    EmojiViewer
  }
})
export default class BaseTicketCard extends BaseTicket {
  @Prop({ default: null }) readonly ticket!: Ticket;

  @Prop({ default: false }) readonly isAssignmentTicket!: boolean;

  @Prop({ default: true }) readonly showStatus!: boolean;

  @Prop({ default: true }) readonly showCreationDate!: boolean;

  @Prop({ default: false }) readonly showAssignee!: boolean;

  @Prop({ default: true }) readonly fixedHeight!: boolean;

  @Prop({ default: 2 }) readonly showMaxReactions!: number;

  @Prop({ default: true }) readonly truncate!: boolean;

  @Prop({ default: () => [] }) readonly menuOptions!: Array<MenuOption>;

  @Getter organizationTopicsById!: (id: string) => Topic;

  @Getter userById!: (id: string) => User;

  @Getter allTeamsById!: (id: string) => Team;

  loading = false;

  get cardHeightClass(): string {
    return this.fixedHeight ? 'fixed-height' : 'flexible-height';
  }

  get timePassedFromCreation(): string {
    return this.$date(this.ticket.lastModifiedDate).fromNow();
  }

  get assigneeAvatar(): string | null {
    const assignee = this.ticket.assignee ? this.userById(this.ticket.assignee) : null;
    return assignee ? (assignee.avatar || null) : null;
  }

  get creatorUserNameAndUnit(): string | null {
    const user = this.userById(this.ticket.creator!);
    const fullName = user ? `${user.firstName} ${user.lastName}` : '';
    const unitOrg = this.allTeamsById(user && user.unit!);
    return unitOrg ? `${this.$t('common.by')} ${fullName}, ${unitOrg.name}` : `${this.$t('common.by')} ${fullName}`;
  }

  get getDelegateTypeClass(): string {
    return this.ticket.delegateIsTeam() ? 'team-type' : 'topic-type';
  }

  get getDelegateColor(): string | null {
    const delegate = this.ticket.getDelegateID();
    if (!delegate) {
      return '';
    }
    if (this.ticket.delegateIsTopic() && this.organizationTopicsById(delegate)) {
      return this.organizationTopicsById(delegate).metaData.color;
    }
    return null;
  }

  get getDelegateName(): string {
    const delegate = this.ticket.getDelegateID();
    if (!delegate) {
      return '';
    }
    if (this.ticket.delegateIsTeam()) return this.allTeamsById(delegate)?.name;
    if (this.ticket.delegateIsTopic() && this.organizationTopicsById(delegate)) {
      return this.organizationTopicsById(delegate).metaData.displayName!;
    }
    return '';
  }

  gotoTicketDetails(hashValue = ''): void {
    this.$router.push({
      name: 'TicketDetails',
      hash: hashValue || '',
      params: { id: this.ticket.canonicalId! }
    });
  }

  onMenuOptionClick(option: MenuOption): void {
    this.$emit('onMenuSelect', option);
  }
}
