






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Reaction from '@improve/common-utils/src/model/Reaction';
import User from '@improve/common-utils/src/model/User';
import BaseEmojiPicker from '../widgets/BaseEmojiPicker.vue';
import { Emoji } from '../../types/EmojiTypes';

@Component({
  name: 'EmojiViewer',
  components: {
    BaseEmojiPicker
  }
})
export default class EmojiViewer extends Vue {
  @Prop({ default: null }) readonly currentUser!: User;

  @Prop({ default: -1 }) readonly showMaxReactions!: number;

  @Prop({ default: () => [] }) readonly reactions!: Array<Reaction>;

  @Prop({ default: false }) readonly isWrapped!: boolean;

  get hiddenReactionsCount(): number {
    return this.reactions.length - this.partialReactions.length;
  }

  get partialReactions(): Reaction[] {
    return this.showMaxReactions > 0
      ? this.reactions.slice(0, this.showMaxReactions)
      : this.reactions;
  }

  reactOrUnReact(reaction: Reaction): void {
    this.$emit('reactOrUnReact', reaction);
  }

  areReactionsWrapped(): string {
    return this.isWrapped ? 'ticket-reactions-wrapped' : '';
  }

  getReactionItemSelection(reaction: Reaction): string {
    return this.hasCurrentUserReacted(reaction) ? 'ticket-reactions-selected' : '';
  }

  hasCurrentUserReacted(reaction: Reaction): boolean {
    return reaction.events.filter((_) => _.author === this.currentUser?.id).length > 0;
  }

  getUnicode = (code: string): string => `&#${code.split('-').join(';&#')};`;

  addNewEmoji(emoji: Emoji): void {
    this.$emit('addEmoji', emoji);
  }
}
