














import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BaseStatsPills'
})
export default class BaseStatsPills extends Vue {
  @Prop({ default: '' }) readonly title!: string;

  @Prop({ default: 0 }) readonly count!: number;
}
