




















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'BaseUserAvatar'
})
export default class BaseUserAvatar extends Vue {
  @Prop({ default: undefined }) src !: string;

  @Prop({ default: 128 }) size !: number;

  @Prop({ default: null }) colorClass !: string;

  @Prop({ default: 'far fa-user-alt' }) faIcon !: string;

  get svgHeight(): number {
    // To maintain ratio
    return this.size / 1.5;
  }
}
