














































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BaseTextInput from '../core/BaseTextInput.vue';
import { FrequentEmojis, OtherEmojis, Emoji } from '../../types/EmojiTypes';

@Component({
  name: 'BaseEmojiPicker',
  components: {
    BaseTextInput
  }
})
export default class BaseEmojiPicker extends Vue {
  showMenu = false;

  menuExpanded = false;

  frequentEmojis: Array<Emoji> = FrequentEmojis;

  otherEmojis: Array<Emoji> = OtherEmojis;

  searchFor: string | null = null;

  get emojiSearchResults(): Emoji[] {
    if (this.searchFor && this.searchFor.trim().length > 1) {
      return this.otherEmojis.filter((_) => _.name.indexOf(this.searchFor!) >= 0);
    }
    return this.otherEmojis;
  }

  onEmojiSelect(emoji: Emoji): void {
    this.$emit('input', emoji);
    this.showMenu = false;
  }

  getUnicode = (code: string): string => `&#${code.split('-').join(';&#')};`;
}
