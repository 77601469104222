























import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import User from '@improve/common-utils/src/model/User';
import Ticket from '@improve/common-utils/src/model/Ticket';
import Reaction from '@improve/common-utils/src/model/Reaction';
import ReactionBoard from '@improve/common-utils/src/model/ReactionBoard';
import BaseTicket from '../../helpers/BaseTicket.vue';
import EmojiViewer from '../ticket/EmojiViewer.vue';
import { Emoji } from '../../types/EmojiTypes';

@Component({
  name: 'ReactionsBottomCard',
  components: {
    EmojiViewer
  }
})
export default class ReactionsBottomCard extends BaseTicket {
  @Prop({ required: true }) readonly ticket!: Ticket;

  @Prop({ default: true }) readonly showEmojiViewer!: boolean;

  @Prop({ default: 1 }) readonly showMaxReactions!: number;

  @Getter currentUser?: User;

  @Getter reactionBoardsById!: Map<string, ReactionBoard>;

  get reactionBoard(): ReactionBoard | null {
    return (this.reactionBoardsById
      && this.reactionBoardsById.get(this.ticket.canonicalId!))
      || null;
  }

  get numberOfComments(): string {
    const board = this.reactionBoard;
    return board ? board.comments.length.toString() : '';
  }

  get reactions(): Array<Reaction> {
    const board = this.reactionBoard;
    return board ? this.getReactions(this.ticket, board) : [];
  }

  addEmoji(emoji: Emoji): void {
    this.addNewEmoji(emoji, this.ticket);
  }

  reactOrUnReactEmoji(reaction: Reaction): void {
    if (!this.ticket || !this.currentUser) return;
    this.reactOrUnReact(reaction, this.ticket, this.currentUser);
  }
}
