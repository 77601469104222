
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Vue from 'vue';
import ReactionBoard from '@improve/common-utils/src/model/ReactionBoard';
import Reaction from '@improve/common-utils/src/model/Reaction';
import Ticket from '@improve/common-utils/src/model/Ticket';
import User from '@improve/common-utils/src/model/User';
import { Emoji } from '../types/EmojiTypes';

@Component({
  name: 'BaseTicket'
})
export default class BaseTicket extends Vue {
  @Action reactToTicket!: ActionMethod;

  @Action unReactToTicket!: ActionMethod;

  render(): BaseTicket {
    return this;
  }

  getReactions(ticket: Ticket, reactionBoard: ReactionBoard): Array<Reaction> {
    if (!ticket) return [];
    return reactionBoard.reactions.filter((_) => !!_.events.length);
  }

  hasUserReacted(reaction: Reaction, user: User): boolean {
    return reaction.events.filter((_) => _.author === user.id).length > 0;
  }

  addNewEmoji(emoji: Emoji, ticket: Ticket): void {
    this.reactToTicket({
      ticketId: ticket.canonicalId,
      reactionKey: emoji.htmlCode
    });
  }

  reactOrUnReact(reaction: Reaction, ticket: Ticket, user: User): void {
    if (this.hasUserReacted(reaction, user)) {
      this.unReactToTicket({
        ticketId: ticket.canonicalId,
        reactionKey: reaction.reactionKey
      });
    } else {
      this.reactToTicket({
        ticketId: ticket.canonicalId,
        reactionKey: reaction.reactionKey
      });
    }
  }
}
