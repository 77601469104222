export type Emoji = {
  name: string;
  htmlCode: string;
};

const FrequentEmojis: Array<Emoji> = [
  {
    // 🙂
    name: ':slight_smile:',
    htmlCode: '128578'
  },
  {
    // 🤔
    name: ':thinking:',
    htmlCode: '129300'
  },
  {
    // 😎
    name: ':sunglasses:',
    htmlCode: '128526'
  },
  {
    // 👍
    name: ':thumbsup:',
    htmlCode: '128077'
  },
  {
    // 👎
    name: ':thumbsdown:',
    htmlCode: '128078'
  },
  {
    // 😮
    name: ':open_mouth:',
    htmlCode: '128558'
  },
  {
    // 😭
    name: ':sob:',
    htmlCode: '128557'
  }
];

const OtherEmojis: Array<Emoji> = [
  {
    // 👍
    name: ':thumbsup:',
    htmlCode: '128077'
  },
  {
    // 🤔
    name: ':thinking:',
    htmlCode: '129300'
  },
  {
    // ✅
    name: ':white_check_mark:',
    htmlCode: '9989'
  },
  {
    // 😮
    name: ':open_mouth:',
    htmlCode: '128558'
  },
  // adfasdfasdfasdfadfasdf
  {
    // 🙂
    name: ':slight_smile:',
    htmlCode: '128578'
  },
  {
    // 🤔
    name: ':thinking:',
    htmlCode: 'x1F937-x200D-x2642-xFE0F'
  },
  {
    // 👌
    name: ':ok_hand:',
    htmlCode: '128076'
  },
  {
    // 👀
    name: ':eyes:',
    htmlCode: '128064'
  },
  {
    // 😱
    name: ':scream:',
    htmlCode: '128561'
  },
  {
    // 🙌
    name: ':raised_hands:',
    htmlCode: '128588'
  },
  {
    // 💪
    name: ':muscle:',
    htmlCode: '128170'
  },
  {
    // 🤣
    name: ':joy:',
    htmlCode: '128514'
  },
  {
    // 😬
    name: ':grimacing:',
    htmlCode: '128556'
  },
  {
    // 🤦‍
    name: ':man_facepalming:',
    htmlCode: 'x1F926-x200D-x2642'
  },
  {
    // 😍
    name: ':heart_eyes:',
    htmlCode: '128525'
  },
  {
    // 💅
    name: ':nail_care:',
    htmlCode: '128133'
  },
  {
    // 🙀
    name: ':scream_cat:',
    htmlCode: '128576'
  },
  {
    // 🎉
    name: ':tada:',
    htmlCode: '127881'
  },
  {
    // 😑
    name: ':expressionless:',
    htmlCode: '128529'
  },
  {
    // ❤️
    name: ':heart:',
    htmlCode: '10084;&#65039'
  },
  {
    // 🙏
    name: ':pray:',
    htmlCode: '128591'
  },
  {
    // 🍌
    name: ':banana:',
    htmlCode: '127820'
  },
  {
    // 😕
    name: ':confused:',
    htmlCode: '128533'
  },
  {
    // 😠
    name: ':angry:',
    htmlCode: '128544'
  },
  {
    // 😁
    name: ':grin:',
    htmlCode: '128513'
  },
  {
    // 👏
    name: ':clap:',
    htmlCode: '128079'
  },
  {
    // 😐
    name: ':neutral_face:',
    htmlCode: '128528'
  },
  {
    // 💊
    name: ':pill:',
    htmlCode: '128138'
  },
  {
    // 👋
    name: ':wave:',
    htmlCode: '128075'
  }
];

export {
  FrequentEmojis,
  OtherEmojis
};
