





































































import Vue from 'vue';
import { debounce } from 'lodash';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import BaseTextInput from '@improve/common-components/src/components/core/BaseTextInput.vue';
import BaseStatsPills from '@improve/common-components/src/components/widgets/BaseStatsPills.vue';
import Ticket from '@improve/common-utils/src/model/Ticket';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import TicketStats from '@improve/common-utils/src/types/TicketStats';
import TabContent from '@improve/common-components/src/components/helpers/TabContent.vue';
import BaseTicketCard from '../../../portal/src/components/ticket/BaseTicketCard.vue';

@Component({
  name: 'Improves',
  components: {
    TabContent,
    BaseTextInput,
    BaseTicketCard,
    BaseStatsPills
  }
})
export default class Improves extends Vue {
  @Action getAllTickets!: ActionMethod;

  @Action fetchTransitions!: ActionMethod;

  @Action getTicketStats!: ActionMethod;

  @Action openTicketInPortal!: ActionMethod;

  @Action openEditTicketInPortal!: ActionMethod;

  @Getter activeWorkflowStatuses!: Array<string>;

  tickets = new Array<Ticket>();

  ticketCountTotal = 0;

  ticketStats: TicketStats | undefined;

  search: string | null = null;

  searchInProgress = false;

  private debounceSearchInput = debounce(() => {
    this.tickets = this.tickets
      .filter((_) => _.title
        && _.title?.toLowerCase().indexOf(this.search!.toLowerCase()) >= 0);
  }, 300);

  get filteredTickets(): Ticket[] {
    if (!this.search || this.search.length < 3) {
      return this.tickets;
    }
    return this.tickets
      .filter((_) => _.title
        && _.title?.toLowerCase().indexOf(this.search!.toLowerCase()) >= 0);
  }

  async created(): Promise<void> {
    this.searchInProgress = true;
    await this.fetchTransitions();
    this.ticketStats = await this.getTicketStats();
    this.ticketCountTotal = this.ticketStats!.totalNonArchived;
    this.tickets = await this.getAllTickets();
    this.searchInProgress = false;
  }

  ticketCountPerStatus(status: string): number | undefined {
    const stats = this.ticketStats;
    return stats && stats.byStatus.get(status);
  }

  setMenuOptions(): Array<MenuOption> {
    const options = [];
    options.push(
      {
        title: this.$t('menu.edit').toString(),
        value: 'edit'
      },
      {
        title: this.$t('menu.reassign').toString(),
        value: 'reassign'
      }
    );
    return options;
  }

  async onMenuSelect(ticket: Ticket, option: MenuOption): Promise<void> {
    switch (option.value) {
      case 'edit': {
        this.openEditTicketInPortal(ticket.canonicalId!);
        break;
      }
      case 'reassign': {
        this.openTicketInPortal(ticket.canonicalId);
        break;
      }
      default: {
        // because eslint doesnt like empty block
        break;
      }
    }
  }
}
